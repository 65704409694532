.page {
}
.pageLink,
.breakLink,
.previousLink,
.nextLink {
  text-decoration: none;
  color: var(--chakra-colors-tertiary-500);
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.previous,
.next {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
}
.break {
}

.container {
  display: flex;
  padding: 0;
  list-style: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: var(--chakra-space-4);
}
.active .pageLink {
  text-decoration: var(--chakra-colors-tertiary-500) underline solid;
}
